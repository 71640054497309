








import Vue from 'vue';

export default Vue.extend({
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
